/* prixProduct.css */

.prix-product-container {
  display: flex;
  flex-direction: column;
  padding: 4.5%;
  justify-content: center;
  padding-top: 70px;
}

.first-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.first-row-title-container {
  position: relative;
  width: 40%;
  align-content: center;
  @media (max-width: 700px) {
    width: 65%;
  }
}

.product-title {
  font-size: 40px;
  font-weight: 600;
  color: #404e5c;
  @media (max-width: 700px) {
    font-size: 20px;
  }
}

.first-row-buttons-container {
  position: relative;
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-content: center;
  gap: 10px;

  @media (max-width: 700px) {
    width: 35%;
  }
}

.button-wrapper {
  width: 50%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
}

.share-button {
  text-transform: none !important;
  border-radius: 10px !important;
  font-size: 18px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  color: #d33f49 !important;
}

.share-icon {
  margin-right: 10px !important;
  @media (max-width: 700px) {
    margin-right: 0 !important;
  }
}

.add-button-enabled {
  text-transform: none !important;
  background-color: #d33f49 !important;
  border-radius: 10px !important;
  font-size: 18px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  color: white !important;
}

.add-button-disabled {
  text-transform: none !important;
  background-color: #d33f4970 !important;
  border-radius: 10px !important;
  font-size: 18px !important;
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  color: white !important;
}

.main-content {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  @media (max-width: 700px) {
    flex-direction: column;
  }
}

.left-side {
  width: 45%;
  gap: 2rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  @media (max-width: 700px) {
    width: 100%;
    height: fit-content;
  }
}

.carousel-wrapper {
  padding: 16px;
  /* margin: 8px 8px 8px 0; */
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  overflow: hidden;
  width: 100%;
  height: fit-content; /* Or 100vh for full viewport height */
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 6rem;

  @media (min-width: 700px) {
    /* justify-content: center; */
    min-height: 410px;
  }
  @media (max-width: 700px) {
    min-height: 350px;
    /* height: 50%; */
  }
}

/* Prueba */

.accordion-title {
  font-size: 1.2rem;
  font-weight: 600;
}

.accordion-details {
  max-height: "100px";
  overflow-y: "auto";
}

.markdown-content {
  color: #000;
  font-size: 0.9rem;
  line-height: 1.6;
  background-color: transparent;
  white-space: pre-wrap;
}

.info-accordion-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.right-side {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 45%;
  height: fit-content;
  overflow: hidden;
  @media (max-width: 700px) {
    width: 100%;
  }
}

.right-side-top {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
}

.attributes-container {
  padding: 16px;
  margin: 8px;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  overflow: hidden;
  display: flex;
  width: 100%;
}

.attributes-container.flex-start {
  justify-content: flex-start;
}

.attributes-container.space-between {
  justify-content: space-between;
}

.attribute-wrapper {
  width: 45%;
}

.right-side-bottom {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: space-around;
  height: 80%;
}

.art-selection-container {
  padding: 16px;
  margin: 8px;
  background-color: #fff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
  overflow: hidden;
}

.art-grid-wrapper {
  height: 100%;
}

.custom-paging-list-item {
  list-style: none;
  margin-left: -12px;
  margin-right: 30px;
}

.custom-paging-link {
  display: block;
  text-decoration: none;
}

.custom-paging-image {
  object-fit: cover;
}

@media (max-width: 600px) {
  /* Adjust the breakpoint as per your 'isTab' condition */
  .custom-paging-image {
    width: 45px;
    height: 45px;
  }
}

@media (min-width: 601px) {
  .custom-paging-image {
    width: 70px;
    height: 70px;
  }
}

.warp-image-container {
  width: 210px;
  height: 210px;
  position: relative;
  margin: 0 auto;
}

.mock-up-overlay {
  /* 'background-image' will be set inline due to dynamic content */
  width: 210px;
  height: 210px;
  background-size: cover;
  border-radius: 20px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
}

.art-comparison-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.product-image-wrapper {
  display: flex;
  flex-direction: column;
  height: fit-content;
  width: 40%;
}

.product-image {
  background-color: #eeeeee;
  height: auto;
  border-radius: 10px;
}

.art-image-wrapper {
  display: flex;
  flex-direction: column;
  width: 40%;
  justify-content: center;
  height: 100%;
}

.art-image {
  background-color: #eeeeee;
  height: auto;
  width: auto;
}

.MuiStepper-root {
  padding: 24px 0px !important;
}

.button-group {
  display: flex;
  width: 100%;
  margin: 20px 0;
  justify-content: space-evenly;
}
