.custom-dots li button {
    background-color: #CCCCCC;  
    border-radius: 0;  
    width: 10px;  
    height: 2px;  
    margin: 0;
    /* margin: 0 5px 15px 0;  */
    border: none;  
    padding: 0;  
  }
  .custom-dots li  {
    width: 10px;  
    margin: 3%;
    padding: 0;  
  }
    
  .custom-dots li button:before {
    content: none;
  }

  .custom-dots li.slick-active button {
    background-color: #d33f49;
  }
  
  .custom-dots li button:focus {
    outline: none;
    box-shadow: none; 
  }
  
  .custom-dots-images {
    height: 20%;  /* Occupy 20% of the total carousel height */
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    bottom: -80px
  }
  
  .custom-dots-images li {
    margin-right: 60px;
    list-style: none;
    height: 70px;
  }
  
  .custom-dots-images li img {
    width: 70px;  
    height: 70px;
    object-fit: cover;
    border-radius: 5px;  
    cursor: pointer; 
  }
  
  .slick-prev, .slick-next {
    display: block !important; 
    color: white;
    border-radius: 50%; 
    width: 20px;  
    height: 20px;  
    z-index: 1000; 
    position: absolute;
    top: 50%; 
    transform: translateY(-50%);
  }
  
  .slick-prev {
    left: 0px;
  }
  
  .slick-next {
    right: 0px;
  }

  .slick-prev:before, .slick-next:before {
    font-size: 20px;
    color: rgba(0, 0, 0, 0.1);
  }
  
  .slick-dots {
    width: 100%;
    display: flex !important;
    margin-bottom: 0;
    justify-content: center;
  }

  .slick-track {
    display: flex;
    width: 100%;
    align-items: center;  /* Center the slide content vertically */
  }

  .slick-slider {
    position: relative;
    width: 100%;
    height: auto;
  }
  
  .slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 60%;
    padding: 10px;  /* Optional padding around the image */
    border-radius: 15px;  /* Rounded corners */
    overflow: hidden;  /* Ensure the image doesn't overflow outside the container */
    /* background-col}or: #f9f9f9;  Optional background color behind the image */
    position: relative;
  }
  
  .slick-slide img {
    width: 100%;
    height: 50%;
    object-fit: cover;  /* Ensures the image fills the container */
    border-radius: 15px;  /* Rounded corners for the image itself */
  }

  .custom-slick-slide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px;  /* Optional padding around the image */
    border-radius: 15px;  /* Rounded corners */
    overflow: hidden;  /* Ensure the image doesn't overflow outside the container */
    /* background-color: #f9f9f9;  Optional background color behind the image */
    position: relative;
  }


  /* Prueba */

  .slick-slider {
    height: fit-content; /* Slider should respect the height of the wrapper */
    display: flex; /* Ensures that the content fills the available space */
    flex-direction: column; /* Stack content vertically */
  }
  
  .slick-track {
    height: 100%; /* Make sure the track respects the slider’s height */
    display: flex; /* Align slides within the track */
    justify-content: center;
    align-items: flex-start;
  }
  
  
  .slick-list {
    height: fit-content;
    flex-grow: 1;
    overflow: hidden;
  }

  .slick-slide {
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    max-height: 100% !important;
  }
  
  .slick-slide img {
    height: 100% !important;
    max-height: 100% !important;
    object-fit: contain;
    border-radius: 40px !important;
  }
  
  